"use client"

import { useState } from "react"

export default function AISalaryCalculator() {
    const [jobTitle, setJobTitle] = useState("")
    const [experience, setExperience] = useState("Mid-Level")
    const [location, setLocation] = useState("")
    const [industry, setIndustry] = useState("")
    const [result, setResult] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [copied, setCopied] = useState(false)

    // Brand colors
    const colors = {
        primaryColor: "#4F46E5",
        secondaryColor: "#3730A3",
        textColor: "#333",
        backgroundColor: "#f9fafb",
        buttonHover: "#3730A3",
        modalOverlay: "rgba(0, 0, 0, 0.5)",
        modalBackground: "white",
        sectionBorder: "#E5E7EB",
    }

    const calculateSalary = async () => {
        if (!jobTitle.trim()) {
            setResult("Job Title is required!")
            setIsError(true)
            setShowModal(true)
            return
        }

        setIsLoading(true)
        setIsError(false)

        const prompt = `You are a salary estimation expert. Provide a concise, well-formatted salary estimate for a ${experience} ${jobTitle} in ${
            location || "the United States"
        } within the ${industry || "relevant"} industry.

    Focus on these key areas:
    1. Base Salary (range and average)
    2. Bonuses and additional compensation
    3. Total Compensation
    4. 3-5 key industry trends affecting this role

    Format your response with these EXACT section headings:
    - "Base Salary"
    - "Bonuses and Additional Compensation"
    - "Total Compensation"
    - "Industry Trends"

    Make the information scannable and easy to read. Use plain language and avoid technical jargon. Keep the entire response under 400 words.
    
    DO NOT use markdown formatting symbols. Present the information in a way that addresses the job seeker's need for accurate, actionable salary information.`

        try {
            const response = await fetch(
                "https://api.openai.com/v1/chat/completions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer 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",
                    },
                    body: JSON.stringify({
                        model: "gpt-4o",
                        messages: [{ role: "user", content: prompt }],
                        max_tokens: 700,
                        temperature: 0.7,
                    }),
                }
            )

            const data = await response.json()

            if (data.choices && data.choices.length > 0) {
                setResult(data.choices[0].message.content.trim())
                setShowModal(true)
            } else {
                setResult(
                    "Error: Could not retrieve salary data. Try again later."
                )
                setIsError(true)
                setShowModal(true)
            }
        } catch (error) {
            setResult(
                "Error fetching data. Check your API key or try again later."
            )
            setIsError(true)
            setShowModal(true)
        } finally {
            setIsLoading(false)
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(result).then(
            () => {
                setCopied(true)
                setTimeout(() => setCopied(false), 2000)
            },
            (err) => {
                console.error("Could not copy text: ", err)
            }
        )
    }

    // Format the result text with proper styling
    const formatResultText = (text) => {
        if (!text) return null

        // Split the text into sections based on the headings
        const sections = []
        let currentSection = { heading: null, content: [] }

        // Split the text into paragraphs
        const paragraphs = text.split("\n").filter((p) => p.trim() !== "")

        paragraphs.forEach((paragraph) => {
            // Check if this is a section header
            if (
                paragraph.includes("Base Salary") ||
                paragraph.includes("Bonuses and Additional Compensation") ||
                paragraph.includes("Total Compensation") ||
                paragraph.includes("Industry Trends")
            ) {
                // If we already have content in the current section, push it to sections
                if (currentSection.heading) {
                    sections.push({ ...currentSection })
                }
                // Start a new section
                currentSection = { heading: paragraph, content: [] }
            } else {
                // Add paragraph to current section content
                currentSection.content.push(paragraph)
            }
        })

        // Add the last section
        if (currentSection.heading) {
            sections.push(currentSection)
        }

        // Render the sections
        return sections.map((section, sectionIndex) => (
            <div
                key={`section-${sectionIndex}`}
                style={{
                    marginBottom: "20px",
                    borderBottom:
                        sectionIndex < sections.length - 1
                            ? `1px solid ${colors.sectionBorder}`
                            : "none",
                    paddingBottom: "15px",
                }}
            >
                <h4
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginTop: "5px",
                        marginBottom: "12px",
                        color: colors.primaryColor,
                    }}
                >
                    {section.heading}
                </h4>

                <div style={{ paddingLeft: "5px" }}>
                    {section.content.map((paragraph, paraIndex) => {
                        // Check if this is a numbered list item
                        if (/^\d+\./.test(paragraph.trim())) {
                            const number = paragraph.match(/^\d+\./)[0]
                            const content = paragraph
                                .replace(/^\d+\./, "")
                                .trim()

                            return (
                                <div
                                    key={`para-${sectionIndex}-${paraIndex}`}
                                    style={{
                                        marginBottom: "10px",
                                        display: "flex",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            marginRight: "8px",
                                            minWidth: "20px",
                                        }}
                                    >
                                        {number}
                                    </span>
                                    <span>{content}</span>
                                </div>
                            )
                        }

                        // Regular paragraph
                        return (
                            <p
                                key={`para-${sectionIndex}-${paraIndex}`}
                                style={{
                                    marginBottom: "10px",
                                    lineHeight: "1.6",
                                }}
                            >
                                {paragraph}
                            </p>
                        )
                    })}
                </div>
            </div>
        ))
    }

    return (
        <div
            style={{
                maxWidth: "800px",
                margin: "auto",
                padding: "20px",
                background: "white",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                fontFamily: "Arial, sans-serif",
                position: "relative",
            }}
        >
            <h2
                style={{
                    color: colors.primaryColor,
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                AI Salary Calculator
            </h2>

            <p
                style={{
                    marginBottom: "15px",
                    fontSize: "16px",
                    textAlign: "center",
                }}
            >
                Enter details to get an AI-powered salary estimate based on
                industry trends.
            </p>

            <label
                style={{
                    fontWeight: "bold",
                    display: "block",
                    marginTop: "10px",
                    fontSize: "16px",
                }}
            >
                Job Title *
            </label>
            <input
                type="text"
                placeholder="e.g. Software Engineer"
                style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px",
                    boxSizing: "border-box",
                }}
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
            />

            <label
                style={{
                    fontWeight: "bold",
                    display: "block",
                    marginTop: "10px",
                    fontSize: "16px",
                }}
            >
                Experience Level *
            </label>
            <select
                style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px",
                    boxSizing: "border-box",
                }}
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
            >
                <option key="junior" value="Junior">
                    Junior
                </option>
                <option key="mid" value="Mid-Level">
                    Mid-Level
                </option>
                <option key="senior" value="Senior">
                    Senior
                </option>
            </select>

            <label
                style={{
                    fontWeight: "bold",
                    display: "block",
                    marginTop: "10px",
                    fontSize: "16px",
                }}
            >
                Location (Optional)
            </label>
            <input
                type="text"
                placeholder="e.g. New York, USA"
                style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px",
                    boxSizing: "border-box",
                }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
            />

            <label
                style={{
                    fontWeight: "bold",
                    display: "block",
                    marginTop: "10px",
                    fontSize: "16px",
                }}
            >
                Industry (Optional)
            </label>
            <input
                type="text"
                placeholder="e.g. Tech, Finance, Healthcare"
                style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    marginTop: "5px",
                    boxSizing: "border-box",
                }}
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
            />

            <button
                style={{
                    width: "100%",
                    marginTop: "15px",
                    padding: "12px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    backgroundColor: colors.primaryColor,
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                }}
                onClick={calculateSalary}
                onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = colors.buttonHover)
                }
                onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor =
                        colors.primaryColor)
                }
                disabled={isLoading}
            >
                {isLoading ? "Calculating..." : "Calculate Salary"}
            </button>

            {/* Modal Popup */}
            {showModal && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: colors.modalOverlay,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: colors.modalBackground,
                            borderRadius: "10px",
                            padding: "20px",
                            width: "90%",
                            maxWidth: "600px",
                            maxHeight: "80vh",
                            overflow: "auto",
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                            position: "relative",
                        }}
                    >
                        <button
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "none",
                                border: "none",
                                fontSize: "24px",
                                cursor: "pointer",
                                color: "#666",
                            }}
                            onClick={() => setShowModal(false)}
                        >
                            ×
                        </button>

                        <h3
                            style={{
                                color: colors.primaryColor,
                                marginTop: "5px",
                                marginBottom: "20px",
                                fontSize: "24px",
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Salary Estimate Results
                        </h3>

                        {isLoading ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    padding: "30px 0",
                                    color: colors.primaryColor,
                                    fontWeight: "bold",
                                }}
                            >
                                Calculating your salary estimate...
                            </div>
                        ) : isError ? (
                            <div
                                style={{
                                    padding: "15px",
                                    backgroundColor: "#FEE2E2",
                                    color: "#DC2626",
                                    borderRadius: "5px",
                                    marginBottom: "15px",
                                }}
                            >
                                {result}
                            </div>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: "#F9FAFB",
                                    padding: "20px",
                                    borderRadius: "5px",
                                    marginBottom: "15px",
                                    lineHeight: "1.5",
                                }}
                            >
                                {formatResultText(result)}
                            </div>
                        )}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                                marginTop: "20px",
                            }}
                        >
                            <button
                                style={{
                                    padding: "12px 24px",
                                    backgroundColor: colors.primaryColor,
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    transition: "background-color 0.3s ease",
                                    width: "180px",
                                }}
                                onClick={copyToClipboard}
                                onMouseOver={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                        colors.buttonHover)
                                }
                                onMouseOut={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                        colors.primaryColor)
                                }
                            >
                                {copied ? "Copied!" : "Copy to Clipboard"}
                            </button>

                            <button
                                style={{
                                    padding: "12px 24px",
                                    backgroundColor: "#F3F4F6",
                                    color: "#374151",
                                    border: "1px solid #D1D5DB",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    transition: "background-color 0.3s ease",
                                    width: "100px",
                                }}
                                onClick={() => setShowModal(false)}
                                onMouseOver={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                        "#E5E7EB")
                                }
                                onMouseOut={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                        "#F3F4F6")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
